<template>
  <div style="background: #fff;color: #000;">
    <van-nav-bar title="同步配件价格" left-arrow placeholder :fixed="true" :z-index="999" @click-left="back">
    </van-nav-bar>
    <div class="mian">
      <div class="pjmian" v-for="(item, idx) in sjlist" :key="idx">
        <div class="mian_a">
          <div>供应商：{{ item.supplierName }}</div>
          <!-- <div>供应商：询价汽配商</div> -->
        </div>
        <div class="mian_b">
          <div>联系人：{{ item.supplierLinkman }}</div>
          <div>手机号：{{ item.supplierPhone }}</div>
        </div>
        <div class="mian_c" v-for="(items, idxs) in item.list" :key="idxs">
          <div class="pimin">
            <span>{{ items.name }}</span>

            <div @click="setadd(items.data[0])">x{{ items.data[0].partCount }}</div>
          </div>
          <div class="pjdiv">
            <div class="" v-for="(it, is) in items.data" :key="is" v-if="it.price > 0">
              <div class="flix">
                <div>{{ it.classify }}：<span class="red">￥{{ it.price }}</span></div>
                <div><span v-if="it.status == 0 || it.status == 1" class="red"> (已下单)</span></div>
                <van-checkbox v-model="it.checked" @click="setcheckbox(items, it)" v-if="!items.disabled" :disabled="items.disabled"></van-checkbox>
                <van-checkbox v-model="it.checked" v-else :disabled="items.disabled"></van-checkbox>
              </div>
              <div class="flxsxs" v-if="is == 1">
                <div class="bz">备注:<span>{{ it.des }}</span></div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div style="height: 60px;"></div>
    <div class="bot">
      <van-button round type="danger" @click="tongbu()">同步配件询价</van-button>

    </div>
  </div>
</template>
  
<script>
import { askPriceList, pricepart } from "@/api/check";

export default {
  data() {
    return {
      sjlist: [],
      gid: this.$route.query.gid,
      oid: this.$route.query.oid,
      askPricePartIds: [],
      token: "",
    };
  },

  methods: {
    setcheckbox(items, it) {
      console.log(items);
      // if(it.)
      for (var i in items.data) {
        if (it.id != items.data[i].id) {
          items.data[i].checked = false;
        }
        for (var j in this.askPricePartIds) {
          if (items.data[i].id == this.askPricePartIds[j]) {
            if (!items.data[i].checked) {
              this.askPricePartIds.splice(j, 1);
            }
          }
        }
      }

      if (it.checked) {
        this.askPricePartIds.push(it.id);
      } else {
        this.askPricePartIds.forEach((item, idx) => {
          if (it.id == item) {
            this.askPricePartIds.splice(idx, 1);
          }
        });
      }
      this.supplierId = it.supplierId;
      this.$forceUpdate();
      console.log(this.supplierId);
      console.log(this.askPricePartIds);
    },
    tongbu() {
      // const data = {
      //   ids:
      // };
      pricepart(this.askPricePartIds).then((e) => {
        if (e.code == 200) {
          this.$toast.success("同步成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        }
      });
    },
    getshangjia() {
      var _this = this;
      askPriceList({ oid: this.oid, gid: this.gid }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          let listArr = [];
          if (res.data.length == 0) {
            return;
          }
          res.data.map((v, i) => {
            var data = v.askPricePartDOList;
            data.map(function (el, index) {
              if (el.status == 0 || el.status == 1) {
                el.checked = true;
              } else {
                el.checked = false;
              }

              if (el.status == 0 || el.status == 1) {
                if (el.partName == v.partName) {
                  _this.$set(v, "status2", v.partName);
                }
              }
              if (el.arrivalTime) {
                el.arrivalTime = _this.DateDiffer(
                  el.arrivalTime,
                  _this.createdDate
                );
              }
              for (var i = 0; i < listArr.length; i++) {
                if (listArr[i].supplierName == el.supplierName) {
                  listArr[i].askPricePartDOS.push(el);
                  return;
                }
              }
              // 第一次对比没有参照，放入参照
              listArr.push({
                supplierName: el.supplierName,
                supplierPhone: el.supplierPhone,
                supplierLinkman: el.supplierLinkman,
                address: el.address,
                askPriceTime: el.askPriceTime,
                billNumber: el.billNumber,
                carNo: el.carNo,
                partId: el.partId,
                partName: el.partName,
                partTotalCount: el.partTotalCount,
                unPricedCount: el.unPricedCount,

                askPricePartDOS: [el],
              });
            });
          });
          listArr.map((v, i) => {
            v.list = this.sortArr(v.askPricePartDOS, "partId");
          });
          this.sjlist = listArr;
          console.log(listArr);
        }
      });
    },
    sortArr(arr, str) {
      var _arr = [],
        _t = [],
        // 临时的变量
        _tmp;

      // 按照特定的参数将数组排序将具有相同值得排在一起
      arr = arr.sort(function (a, b) {
        var s = a[str],
          t = b[str];

        return s < t ? -1 : 1;
      });

      if (arr.length) {
        _tmp = arr[0][str];
      }
      // console.log( arr );
      // 将相同类别的对象添加到统一个数组
      for (var i in arr) {
        if (arr[i][str] === _tmp) {
          _t.push(arr[i]);
        } else {
          _tmp = arr[i][str];
          _arr.push(_t);
          _t = [arr[i]];
        }
      }
      // 将最后的内容推出新数组
      _arr.push(_t); //获取到所有整合数据
      console.log(_arr); //可以得到图三
      // 终极整合
      var integration = [];
      _arr.forEach((item) => {
        var eee = { name: "", disabled: false, data: [] };
        item.forEach((self) => {
          if (eee.name != self.payType) {
            if (self.status == 0 || self.status == 1) {
              eee.disabled = true;
            }
            eee.name = self.partName;
            eee.data.push(self);
          } else {
            eee.data.push(self.money);
          }
        });
        integration.push(eee);
      });
      console.log(integration); //图二
      return integration;
    },

    back() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getshangjia();
  },
  mounted() {},
  computed: {},
};
</script>
  
<style lang="less" scoped>
.pjmian {
  overflow: hidden;
  font-size: 14px;
  color: #666;

  .mian_a {
    display: flex;
    align-items: center;
    line-height: 30px;
    padding: 6px 12px;
    justify-content: space-between;
    background: rgb(238, 252, 241);
    font-size: 15px;
    font-weight: bold;
    color: #000;
  }

  .mian_b {
    display: flex;
    align-items: center;
    line-height: 30px;
    padding: 0 12px;
    justify-content: space-between;
    border-bottom: 4px solid #f5f5f5;
    div {
      flex: 1;
    }
  }

  .mian_c {
    padding: 0 12px;
    border-bottom: 4px solid #f5f5f5;

    .pimin {
      line-height: 30px;
      border-bottom: 1px solid #f5f5f5;
      color: #333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .pjdiv {
      padding: 5px 0 6px 0;
    }

    .flix {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 30px;
    }
  }
}
.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}
</style>

  
  